import { useEffect, useState } from 'preact/hooks';

export const usePageFocusDetection = (forceOff = false) => {
    const [isPageActive, setIsPageActive] = useState(true);
    const [isMouseInside, setIsMouseInside] = useState(true);

    useEffect(() => {
        if (forceOff) return undefined;

        const handlePageVisibilityChange = () => {
            const isPageActive = !document.hidden && document.visibilityState === 'visible';

            setIsPageActive(isPageActive);
        };

        const handlePointerLeave = () => {
            setIsMouseInside(false);
        };

        const handlePointerEnter = () => {
            setIsMouseInside(true);
        };

        document.addEventListener('visibilitychange', handlePageVisibilityChange);
        document.addEventListener('pointerleave', handlePointerLeave);
        document.addEventListener('pointerenter', handlePointerEnter);

        return () => {
            document.removeEventListener('visibilitychange', handlePageVisibilityChange);
            document.removeEventListener('pointerleave', handlePointerLeave);
            document.removeEventListener('pointerenter', handlePointerEnter);
        };
    }, [forceOff, setIsPageActive, setIsMouseInside]);

    return isPageActive && isMouseInside;
};
