import type { FunctionalComponent } from 'preact';

import { Button } from 'src/shared/components/button/button.js';
import { Modal } from 'src/shared/components/modal/modal.js';

import { cnBlockSwitchToTechExitPopup1 } from './block-switch-to-tech-exit-popup-1.constants.js';

import './block-switch-to-tech-exit-popup-1.css';

import './__button/block-switch-to-tech-exit-popup-1__button.css';
import './__content/block-switch-to-tech-exit-popup-1__content.css';
import './__switch-text/block-switch-to-tech-exit-popup-1__switch-text.css';
import './__text/block-switch-to-tech-exit-popup-1__text.css';
import './__title/block-switch-to-tech-exit-popup-1__title.css';

type BlockAttributes = {
    idleTimeout?: string;
    preTitle: string;
    postTitle: string;
    text: string;
    buttonText: string;
    buttonLink: string;
    isOpen: boolean;
    closeModal: VoidFunction;
};

export interface BlockSwitchToTechExitPopup1Props extends BlockAttributes {}

export const BlockSwitchToTechExitPopup1: FunctionalComponent<BlockSwitchToTechExitPopup1Props> = (props) => {
    const { preTitle, postTitle, text, buttonLink, buttonText, isOpen, closeModal } = props;

    const isLinkAnchorToPage = buttonLink.startsWith('#');
    const linkTarget = isLinkAnchorToPage ? undefined : '_blank';
    const handleLinkClick = isLinkAnchorToPage ? closeModal : undefined;

    return (
        <Modal className={cnBlockSwitchToTechExitPopup1()} open={isOpen} onClose={closeModal}>
            <div className={cnBlockSwitchToTechExitPopup1('content')}>
                <div className={cnBlockSwitchToTechExitPopup1('title')}>
                    <span className={cnBlockSwitchToTechExitPopup1('switch-text')}>{preTitle}</span>
                    <br />
                    {postTitle}
                </div>
                <div className={cnBlockSwitchToTechExitPopup1('text')}>{text}</div>
                <Button
                    autoFocus
                    id="switch-to-tech-exit-popup-button"
                    className={cnBlockSwitchToTechExitPopup1('button')}
                    mode="light"
                    target={linkTarget}
                    onClick={handleLinkClick}
                    href={buttonLink}
                >
                    {buttonText}
                </Button>
            </div>
        </Modal>
    );
};
