import type { FunctionalComponent } from 'preact';
import { useEffect } from 'preact/hooks';

import { useBooleanState } from 'src/shared/hooks/use-boolean-state/use-boolean-state.js';

import { useShouldShowExitPopup } from './block-switch-to-tech-exit-popup-1.hooks/use-should-show-exit-popup.js';
import { BlockSwitchToTechExitPopup1 } from './block-switch-to-tech-exit-popup-1.js';
import type { BlockSwitchToTechExitPopup1Props } from './block-switch-to-tech-exit-popup-1.js';

export type BlockSwitchToTechExitPopup1ContainerProps = Omit<BlockSwitchToTechExitPopup1Props, 'isOpen' | 'closeModal'>;

export const BlockSwitchToTechExitPopup1Container: FunctionalComponent<BlockSwitchToTechExitPopup1ContainerProps> = (
    props,
) => {
    const { idleTimeout } = props;

    const idleTimeoutInSec = Number(idleTimeout) || 0;
    const shouldShowExitPopup = useShouldShowExitPopup(idleTimeoutInSec);

    const { state: isOpen, setTrue: openModal, setFalse: closeModal } = useBooleanState();

    useEffect(() => {
        if (shouldShowExitPopup) openModal();
    }, [shouldShowExitPopup, openModal]);

    return <BlockSwitchToTechExitPopup1 {...props} isOpen={isOpen} closeModal={closeModal} />;
};
