import { useEffect, useState } from 'preact/hooks';

import { usePageFocusDetection } from './use-page-focus-detection.js';
import { useUserIdleDetection } from './use-user-idle-detection.js';

export const useShouldShowExitPopup = (idleTimeoutInSec: number) => {
    const [isShouldShowExitPopup, setIsShouldShowExitPopup] = useState(false);

    const forceOffDetectors = isShouldShowExitPopup;
    const isPageInUserFocus = usePageFocusDetection(forceOffDetectors);
    const isUserActive = useUserIdleDetection(idleTimeoutInSec, forceOffDetectors);

    useEffect(() => {
        if (!isPageInUserFocus || !isUserActive) setIsShouldShowExitPopup(true);
    }, [isPageInUserFocus, isUserActive]);

    return isShouldShowExitPopup;
};
