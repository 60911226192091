import { useEffect, useState } from 'preact/hooks';

export const useUserIdleDetection = (idleTimeoutInSec: number, forceOff = false) => {
    const [isUserActive, setIsUserActive] = useState(true);

    useEffect(() => {
        if (idleTimeoutInSec <= 0 || forceOff) return undefined;

        const timeoutMs = idleTimeoutInSec * 1000;
        const makeTimer = () =>
            setTimeout(() => {
                setIsUserActive(false);
            }, timeoutMs);

        let idleTimer = makeTimer();
        const resetTimer = () => {
            setIsUserActive(true);

            clearTimeout(idleTimer);
            idleTimer = makeTimer();
        };

        window.addEventListener('pointermove', resetTimer);
        window.addEventListener('keydown', resetTimer);
        window.addEventListener('scroll', resetTimer);
        window.addEventListener('pointerdown', resetTimer);

        return () => {
            clearTimeout(idleTimer);

            window.removeEventListener('pointermove', resetTimer);
            window.removeEventListener('keydown', resetTimer);
            window.removeEventListener('scroll', resetTimer);
            window.removeEventListener('pointerdown', resetTimer);
        };
    }, [idleTimeoutInSec, forceOff]);

    return isUserActive;
};
